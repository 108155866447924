/**
 * It is a mixin that can be included by users who use the crux-text-component and give autocomplete as true. It prefills the data and methods that are required for some specific fields.
 * @plugin cruxTextareaBindResize
 * @author anuja.manoharan
 * @version 1.0.0
 */
Lyte.Mixin.register("crux-textautocomplete-options", {//No I18n
	methods : {
		/**
		 * fetchData is called when user clicks on the input or searches something in it. The below method handles the case of COMPANY field and COUNTRY-like fields.
		 * @author anuja.manoharan
		 * @version 1.0.0
		 * @returns either a promise with resolved data or an array of data
		 */
		fetchData : function(val, index, field){
			switch(field.column_name){
			case "COMPANY":
				if(!store.modelFor(moduleRecordMapping.Accounts.id)){
					store.registerModel(moduleRecordMapping.Accounts.id, {}, {extends : "entity"});
					this.accountFieldsFetched = false;
				}
				var filter = {
                        group_operator: "OR",
                        group: [{
                            comparator: "starts_with",
                            field: {
                                api_name: "Account_Name"
                            },
                            value: val
                        }, {
                            comparator: "contains",
                            field: {
                                api_name: "Account_Name"
                            },
                            value: val
                        }]
                    }
				var prom = [store.findAll(moduleRecordMapping.Accounts.id, {filters : filter, page : index, per_page : 10, sort_by : "Account_Name",
					sort_order : "asc", approved : "both"}, false, false)];
				if(!this.accountFieldsFetched){
					prom.push(store.findRecord("module", moduleRecordMapping.Accounts.id));
					this.accountFieldsFetched = true;
				}
				return Lyte.resolvePromises(prom).then(function(response){
					return response[0][moduleRecordMapping.Accounts.id];
				})
			case "COUNTRY":
			case "SHIPPINGCOUNTRY":
			case "MAILINGCOUNTRY":
			case "BILLINGCOUNTRY":
			case "OTHERCOUNTRY":
				return I18n.properties.COUNTRY_LIST2;
			}
		}
	},
	getTextAutocompleteOptions : function(field){
		/**
		 * This will set the autocomplete options for some predefined fieldss
		 * @author anuja.manoharan
		 * @version 1.0.0
		 * @params field info from which column_name is used
		 * @returns an object that specifies the properties required for autocomplete
		 */
		var obj = {enable : true};
		switch(field.column_name){
		case "COMPANY":
			obj = Object.assign(obj, {yield : true, externalSearch : true, minLength : 3, displayField : "Account_Name", module : "Accounts",
				idField : "id"});
			break;
		case "COUNTRY":	
		case "SHIPPINGCOUNTRY":
		case "MAILINGCOUNTRY":
		case "BILLINGCOUNTRY":
		case "OTHERCOUNTRY":
			obj = Object.assign(obj, {minLength : 2, method : "startsWith",displayField : "name", content : this.executeMethod("fetchData", undefined, undefined, field)});
			break;
		default :
			obj.enable = false;
		}
		return obj;
	}
});

